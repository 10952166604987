export default function convertJobType(jobType) {
	switch (jobType) {
		case 'cdi':
			return 'CDI'
		case 'cdd':
			return 'CDD'
		case 'internship':
			return 'Stage / Alternance'
		case 'volunteering':
			return 'Volontariat / Service civique'
		default:
			return jobType
	}
}
